/**
 * Isso é uma cópia do $fieldTypes declarado no App.vue
 * e deve ser atualizado manualmente caso adicionem novos campos
 * 
 * // TODO analisar a troca daquele objeto por este enum em todo o projeto
 */
export const FieldTypeEnum = {
  TEXT: "text",
  TEXTAREA: "textarea",
  AUTOCOMPLETE: "autocomplete",
  AUTOCOMPLETE_MULTIPLE: "autocomplete_multiple",
  SELECT: "select",
  RADIO: "radio",
  NUMBER: "number",
  DOUBLE: "double",
  MONEY: "money",
  MONEY2: "money2",
  DATETIME: "datetime",
  DATE: "date",
  MONTH: "month",
  FROMNOW: "fromnow",
  DURATION: "durarion",
  PASSWORD: "password",
  TIME: "time",
  BIGTIME: "bigtime",
  HTML: "html",
  CHECKBOXES: "checkboxes",
  COMBOBOX: "combobox",
  SWITCH: "switch",
  FILE: "file",
  SUBTITLE: "subtitle",
  CPF: "cpf",
  CNPJ: "cnpj",
  TELEPHONE: "telephone",
  DOWNLOAD: "download",
  TEXT_CHIP: "text_chip",
};
